import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { Box, CircularProgress, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const LoadingContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '100vh',
  background: theme.palette.background.default,
  gap: theme.spacing(2),
  '& .MuiCircularProgress-root': {
    color: theme.palette.primary.main
  }
}));

const LoadingText = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
  fontWeight: 500,
  background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.light})`,
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  animation: 'pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite',
  '@keyframes pulse': {
    '0%, 100%': {
      opacity: 1
    },
    '50%': {
      opacity: 0.7
    }
  }
}));

const ProtectedRoute = ({ children, isAuthenticated, isLoading }) => {
  const location = useLocation();

  if (isLoading) {
    return (
      <LoadingContainer>
        <CircularProgress size={60} thickness={4} />
        <LoadingText variant="h6">
          Checking authentication...
        </LoadingText>
      </LoadingContainer>
    );
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

export default ProtectedRoute;